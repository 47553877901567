import React from "react"

import { useProjectsImage } from "./useProjectsImage"
import Image from "gatsby-image"

export const useProjectsData = () => {
  const imgs = useProjectsImage()
  return [
    [
      {
        title: "FMCG",
        description: "Wsparcie sprzedaży impulsowej w sklepach.",
        url: "/fmcg",
        img: <Image fixed={imgs.cheetos.childImageSharp.fixed} />,
      },
      {
        title: "Farmaceutyczna",
        description: "Materiały reklamowe do aptek.",
        url: "/farmaceutyczna",
        img: <Image fixed={imgs.farm.childImageSharp.fixed} />,
      },
      {
        title: "Chłodnicza",
        description: "Dekoracje i refresh komercyjnych lodówek.",
        url: "/chlodnicza",
        img: <Image fixed={imgs.lech.childImageSharp.fixed} />,
      },
      {
        title: "Podłogowa",
        description: "Ekspozytory na panele, wykładziny i akcesoria.",
        url: "/ceramiczna",
        img: <Image fixed={imgs.pod.childImageSharp.fixed} />,
      },
      {
        title: "Ceramiczna",
        description: "Ekspozycja płytek i ceramiki sanitarnej.",
        url: "/podlogowa",
        img: <Image fixed={imgs.cer.childImageSharp.fixed} />,
      },
      {
        title: "Inna",
        description: "Materiały wsparcia dla innych branż.",
        url: "/inne",
        img: <Image fixed={imgs.b_inne.childImageSharp.fixed} />,
      },
    ],
    [
      {
        title: "Duże POSM",
        description: "Regały i ekspozytory podłogowe większego formatu.",
        url: "/duze-posm",
        img: <Image fixed={imgs.jordan.childImageSharp.fixed} />,
      },
      {
        title: "Małe POSM",
        description: "Dyspensery i stojaczki na ladę, półkę, kasę sklepową.",
        url: "/male-posm",
        img: <Image fixed={imgs.safety.childImageSharp.fixed} />,
      },
      {
        title: "Shop-in-shop",
        description: "Stoiska systemowe, aranżacje wnętrz, ścianki sklepowe.",
        url: "/shop-in-shop",
        img: <Image fixed={imgs.shop.childImageSharp.fixed} />,
      },
      {
        title: "Stojaki sklejkowe",
        description: "Dedykowane stojaki POS wykonane ze sklejki.",
        url: "/stojaki-sklejkowe",
        img: <Image fixed={imgs.stojaki.childImageSharp.fixed} />,
      },
      {
        title: "Inne",
        description: "Pozostałe materiały.",
        url: "/typ-inne",
        img: <Image fixed={imgs.inne.childImageSharp.fixed} />,
      },
    ],
  ]
}
