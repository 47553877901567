import {useCaseStudyImage} from './useCaseStudyImage';
import Image from 'gatsby-image';
import React from 'react';

export const useCaseStudyData = () => {
  const data = useCaseStudyImage();

  return [
    {
      title: (
        <>
          Cersanit i Opoczno<br></br> – projekt shop in shop
        </>
      ),
      shortText: (
        <>
          Ofensywa marketingowa prowadzona przez grupę Rovese (właściciela marek
          Cersanit i&nbsp;Opoczno) na krajowym i&nbsp;zagranicznym rynku płytek
          i&nbsp;ceramiki sanitarnej, zaowocowała szeregiem wspólnie
          realizowanych projektów...
        </>
      ),
      text: (
        <>
          Ofensywa marketingowa prowadzona przez grupę Rovese (właściciela marek
          Cersanit i Opoczno) na krajowym i&nbsp;zagranicznym rynku płytek
          i&nbsp;ceramiki sanitarnej, zaowocowała szeregiem wspólnie
          realizowanych projektów i&nbsp;akcji promocyjnych. Nasze działania
          zorientowane na efektywne wsparcie identyfikacji marki oraz sprzedaży
          asortymentu 3D (wanny, umywalki, meble łazienkowe) i&nbsp;2D (płytki
          ceramiczne) rozłożone zostały na dwa etapy wdrożeń, ekspozytorów oraz
          salonów firmowych. Efektem współpracy było wykonanie sklepów dla
          obydwu marek w odświeżonej identyfikacji i nowym formacie.Projekt
          został przez nas zrealizowany poprzez otoczenie go kręgiem
          komplementarnych usług, obejmujących obszary opracowania technicznego,
          wykonania urządzeń handlowych, logistyki i dystrybucji wraz z montażem
          oraz w dalszej kolejności cyklicznych wymian eksponowanego asortymentu
          w punktach handlowych.Każdy sklep ze względu na inne możliwości
          przestrzenne był rozpracowywany indywidualnie przez dział
          konstrukcyjny i projektowy. Po dokonaniu pomiarów i konsultacji z
          właścicielem punktu oraz opiekunem tematu na projekt naniesione
          zostały dedykowane zabudowy, dekoracje oraz urządzenia handlowe, z
          uwzględnieniem zachowania odpowiedniej ergonomii i estetyki z już
          istniejącymi elementami wyposażenia.Produkcją i konfekcjonowaniem
          zestawów ekspozycyjnych zajęła się nasza doświadczona kadra
          produkcyjna, w ścisłej współpracy z działem logistyki. czuwającym nad
          optymalizacją dystrybucji do sklepów w różnych obszarach kraju i za
          granicami - sklepy powstawały na terenie Polski i Rumunii.
        </>
      ),
      href: '/case-study/cersanit',
      image: (
        <Image
          imgStyle={{objectFit: 'contain'}}
          fluid={data.image_1.childImageSharp.fluid}
        />
      ),
    },
    {
      title: <>Futuro i&nbsp;Viscoplast Mini kampanie</>,
      shortText: (
        <>
          Założeniem tego projektu było przygotowanie kompletu materiałów POS
          dla produktów marek Futuro, Nexcare i&nbsp;Viscoplast, będących
          własnością firmy 3M. Do naszych zadań należało zaprojektowanie
          możliwie...
        </>
      ),
      text: (
        <>
          Założeniem tego projektu było przygotowanie kompletu materiałów POS
          dla produktów marek Futuro, Nexcare i&nbsp;Viscoplast, będących
          własnością firmy 3M. Do naszych zadań należało zaprojektowanie
          możliwie uniwersalnego zestawu ekspozytorów sprzedażowo-promocyjnych,
          przeznaczonych głównie do punktów aptecznych, sklepów sportowych
          i&nbsp;medycznych. Dla Futuro, marki nowoczesnych opasek uciskowych,
          przygotowaliśmy rozwiązania dla sklepów o&nbsp;mniejszych
          i&nbsp;większych formatach. W zależności od dostepnego miejsca lub
          potrzeb właścicieli punktów, zaoferowaliśmy klientowi stojaki
          podłogowe, ladowe i&nbsp;zawieszane. Marki plastrów i&nbsp;opatrunków
          Nexcare oraz Viscoplast otrzymały wzmocnienie identyfikacji dzięki
          wprowadzeniu nowych rodzajów ekspozytorów. Podobnie jak w przypadku
          Futuro, nasza propozycja objęła sklepy o różnych rozmiarach i
          dostępnym miejscu. Zakres prac w przypadku wszystkich projektów
          obejmował wykonanie wizualizacji podpartych analizą potrzeb klienta i
          możliwości merchandisingowych docelowych punktów. W opracowaniu
          konstrukcyjno-technologicznym już na wstępie uwzględniliśmy potrzebę
          dystrybucji naszych ekspozytorów do całej Europy, a&nbsp;także poza
          jej granice.
        </>
      ),
      href: '/case-study/futuro-viscoplast',
      image: (
        <Image
          imgStyle={{objectFit: 'contain'}}
          fluid={data.image_2.childImageSharp.fluid}
        />
      ),
    },
    {
      title: (
        <>
          Shell deli2go<br></br> – mała gastronomia na stacjach paliw
        </>
      ),
      shortText: (
        <>
          Główne założenie projektu deli2go stanowiło stworzenie przytulnego
          obszaru małej gastronomii typu shop-in-shop w&nbsp;sklepach stacji
          paliw Shell. W&nbsp;swoich założeniach estetyka i&nbsp;funkcjonalność
          pozwalała kierowcy...
        </>
      ),
      text: (
        <>
          Główne założenie projektu deli2go stanowiło stworzenie przytulnego
          obszaru małej gastronomii typu shop-in-shop w sklepach stacji paliw
          Shell. W swoich założeniach estetyka i&nbsp;funkcjonalność pozwalała
          kierowcy na chwilę relaksu przy świeżo parzonej kawie oraz szerokiej
          gamie ciepłych i&nbsp;zimnych przekąsek. Strefa deli2go została
          wykonana i&nbsp;zamontowana tak, aby poprzez swoją oryginalność
          zachęcać i&nbsp;przyciągać klientów przy jednoczesnym zachowaniu
          harmonii z&nbsp;całością istniejącej zabudowy sklepowej oraz
          stylistyką przyjętą dla całej sieci. Działania wdrożeniowe obejmowały
          przygotowanie nowych zabudów oraz umeblowania, demontaż poprzednich
          a&nbsp;także modernizację instalacji elektrycznych i&nbsp;wodnych do
          wymogów nowych urządzeń. Dzięki doświadczeniu naszych ekip montażowych
          średni czas realizacji jednej zabudowy przewidziany przez klienta na 1
          tydzień został skrócony do 3 dni. Pod względem konstrukcyjnym w celu
          zachowania jednolitej linii wystroju, każdy z modułów wyposażenia
          (zabudowy i&nbsp;meble) został opracowany jako parametryczny model,
          pozostawiając opcję skalowalności w&nbsp;celu dopasowania do
          nietypowych konfiguracji punktów. Przystosowane w&nbsp;ten sposób
          elementy posłużyły do przygotowania indywidualnych dla każdej stacji
          planogramów oraz równolegle rozkrojów materiału optymalizujących
          koszty produkcji i&nbsp;logistyki. Zaprojektowaliśmy i dokonaliśmy
          rebrandingu wnętrz 233 stacji Shell, zapewniając możliwie ergonomiczne
          układy uwzględniające zróżnicowane gabarytowo i&nbsp;funkcyjnie
          urządzenia gastronomiczne - regały chłodnicze, kawiarki, piece
          konwekcyjne i inne.
        </>
      ),
      href: '/case-study/shell-deli2go',
      image: (
        <Image
          imgStyle={{objectFit: 'contain'}}
          fluid={data.image_3.childImageSharp.fluid}
        />
      ),
    },
    {
      title: (
        <>
          VOX - modułowy system <br/>
          ekspozycyjny
        </>
      ),
      shortText: (
        <>
          Salony VOX to sieć oferująca klientom możliwość urządzenia wnętrza
          domu w&nbsp;jednym miejscu. Panele podłogowe oraz akcesoria do nich
          stanowią jedną z&nbsp;podstawowych kategorii produktowych przy
          planowaniu pomieszczeń, dlatego...
        </>
      ),
      text: (
        <>
          Salony VOX to sieć oferująca klientom możliwość urządzenia wnętrza
          domu w jednym miejscu. Panele podłogowe oraz akcesoria do nich
          stanowią jedną z&nbsp;podstawowych kategorii produktowych przy
          planowaniu pomieszczeń, dlatego też w ekspozycji tych materiałów
          istotna jest czytelna i&nbsp;atrakcyjna ekspozycja stanowiąca dla nich
          tło. W projekcie systemu ekspozycyjnego Kerradeco uwzględniliśmy te
          cechy, łącząc ze sobą biel oraz jasne drewno w uniwersalny, modularny
          zestaw stojaków. System ten pozwala łączyć ze sobą poszczególne
          elementy w zależności od potrzeb ekspozycji indywidualnych salonów pod
          względem ilościowym, jak i&nbsp;rodzajowym. Poszczególne moduły, m.in.
          panele podłogowe, panele winylowe, listwy przypodłogowe, podkłady,
          oddzielone są za pomocą infopanelu gwarantującego klientowi dostęp do
          wzorników. Nasze materiały zaprojektowane są z&nbsp;myślą
          o&nbsp;łatwym składowaniu, transporcie i&nbsp;montażu w punktach
          sprzedaży, dlatego stosujemy rozwiązania kontrukcyjne i technologiczne
          wykorzystywane w branży meblowej. W nasze POSM zaopatrzyliśmy ponad
          150 salonów VOX w całej Polsce.
        </>
      ),
      href: '/case-study/vox',
      image: (
        <Image
          imgStyle={{objectFit: 'contain'}}
          fluid={data.image_4.childImageSharp.fluid}
        />
      ),
    },
  ];

};
