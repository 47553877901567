import React from 'react';
import {MediumHeader, NormalHeader} from '../components/Typography';
import {useProjectsData} from '../shared/Hooks/Homepage';
import {Experience, Service, Solutions} from '../components/Icons/Homepage';
import {useCaseStudyData} from '../shared/Hooks/Homepage/useCaseStudyData';
import Image from 'gatsby-image';
import {useBackgroundImage} from '../shared/Hooks/Background';

export const useData = () => {
  const projectsData = useProjectsData();
  const caseStudyData = useCaseStudyData();
  const bgImage = useBackgroundImage();

  return {
    title: 'Strona główna',
    slider: [
      {
        text: (
          <>
            <MediumHeader>Wspieramy z&nbsp;rozmachem.</MediumHeader>
            <br></br>
            <NormalHeader color={'white'}>
              Twój projekt obsłużymy od&nbsp;wstępnego szkicu do&nbsp;wdrożenia.
            </NormalHeader>
          </>
        ),
      },
      {
        text: (
          <>
            <MediumHeader>Z wyobraźnią i&nbsp;elastycznie.</MediumHeader>
            <br></br>
            <NormalHeader color={'white'}>
              Stosujemy sprawdzone rozwiązania i&nbsp;nowe koncepcje.
            </NormalHeader>
          </>
        ),
      },
      {
        text: (
          <>
            <MediumHeader>Mamy doświadczenie i&nbsp;wiedzę.</MediumHeader>
            <br></br>
            <NormalHeader color={'white'}>
              Znajdziemy indywidualne rozwiązanie dla&nbsp;Twoich potrzeb.
            </NormalHeader>
          </>
        ),
      },
      {
        text: (
          <>
            <MediumHeader>Pracujemy z&nbsp;pasją.</MediumHeader>
            <br></br>
            <NormalHeader color={'white'}>
              Na każdym etapie dajemy z&nbsp;siebie wszystko i&nbsp;jeszcze trochę.
            </NormalHeader>
          </>
        ),
      },
    ],
    aboutUs: {
      section_title: 'O Nas',
      parallaxText: (
        <Image fixed={bgImage.aboutUs.childImageSharp.fixed}/>
      ),
      title: (
        <>
          Kreacja POSM <br/>
          od A do Z.
        </>
      ),
      text: (
        <>
          Stanowimy zespół specjalistów o wieloletnim doświadczeniu w&nbsp;tworzeniu
          kompleksowych rozwiązaniań wsparcia sprzedaży POSM. Odpowiadamy na
          potrzeby klientów oferując dopasowane do indywidualnych wymogów materiały,
          począwszy od projektu, przez prototyp i produkcję, aż po logistykę i
          wdrożenie w punktach handlowych.
        </>
      ),
      button: {
        href: '/kim-jestesmy',
        text: 'Zobacz wiecej',
      },
    },
    cardsSlider: {
      parallaxText: (
        <Image fixed={bgImage.projects.childImageSharp.fixed}/>
      ),
      label: {
        title: 'Projekty',
        type: 'Typ',
        industry: 'Branża',
      },
      projects: projectsData,
    },
    companyAssets: {
      title: 'Nasze atuty',
      parallaxText: (
        <Image fixed={bgImage.advantages.childImageSharp.fixed}/>
      ),
      data: [
        {
          href: '/kim-jestesmy',
          icon:
            <Experience width="87" height="76px" color={'#D2D2D2'}/>,
          title: 'Wieloletnie doświadczenie.',
          text: (
            <>
              Wykwalifikowana kadra i&nbsp;bogata baza wiedzy o&nbsp;POSM. Poznaj
              naszą firmę i&nbsp;załogę.
            </>
          ),
        },
        {
          href: '/kim-jestesmy#wsparcie-na-kazdym-etapie',
          icon:
            <Service width="94px" height="60px" color={'#D2D2D2'}/>,
          title: 'Kompleksowa obsługa projektów.',
          text: (
            <>
              Od wizualizacji do&nbsp;wdrożenia -&nbsp;zobacz, co&nbsp;oferujemy
              w&nbsp;ramach standardu usług.
            </>
          ),
        },
        {
          href: '/nasze-projekty',
          icon:
            <Solutions width="94px" height="93px" color={'#D2D2D2'}/>,
          title: 'Indywidualne rozwiązania.',
          text: (
            <>
              Ekspozycje szyte na miarę. Sprawdź dlaczego warto z&nbsp;nami pracować.
            </>
          ),
        },
      ],
    },
    caseStudy: {
      parallaxText: (
        <Image fixed={bgImage.case.childImageSharp.fixed}/>
      ),
      title: 'Case study',
      btnText: 'Zobacz więcej',
      data: caseStudyData,
    },
    partners: {
      parallaxText: (
        <Image fixed={bgImage.clients.childImageSharp.fixed}/>
      ),
      title: 'Nasi klienci',
    },
  };
};
