import React from 'react';
import {useData} from '../data/homepage';

import Homepage from '../sections/Homepage';

const IndexPage = () => {
  const data = useData();

  return (
    <Homepage data={data}/>
  );
};

export default IndexPage;
